declare global {
  interface Window {
    recaptchaVerifier: any;
    confirmationResult: any;
    grecaptcha: any;
    recaptchaWidgetId: any;
  }
}

declare const grecaptcha: {
  reset: (widgetId: string) => void;
};

export {};

import React, { useEffect, useRef, useState } from "react";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  onAuthStateChanged,
} from "firebase/auth";
import {
  useRegisterMutation,
  useMeLazyQuery,
  MeQuery,
  useMutateMeMutation,
  useMeQuery,
} from "src/generated/graphql";
import { useRouter } from "next/router";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import Link from "next/link";
import { FaSpinner } from "react-icons/fa";
import { IoArrowBack } from "react-icons/io5";
import {
  useRequestEmailOtpMutation,
  useVerifyEmailOtpMutation,
} from "src/generated/graphql";
import { AiOutlineCheckCircle } from "react-icons/ai";

const Login2 = () => {
  const [UIState, setUIState] = useState("phoneInput");
  const router = useRouter();
  const auth = getAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("61");
  const [email, setEmail] = useState("");
  const phoneNumberInputRef = useRef<HTMLInputElement>(null);
  const [phoneOtp, setPhoneOtp] = useState(Array(6).fill(""));
  const [emailOtp, setEmailOtp] = useState(Array(6).fill(""));
  const phoneOtpInputs = useRef<(HTMLInputElement | null)[]>([]);
  const emailOtpInputs = useRef<(HTMLInputElement | null)[]>([]);
  const [countdownSeconds, setCountdownSeconds] = useState(30);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [requestEmailOtp] = useRequestEmailOtpMutation();
  const [verifyEmailOtp] = useVerifyEmailOtpMutation();
  const [mutateMeMutation] = useMutateMeMutation();

  const { data: meData } = useMeQuery();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && !user.isAnonymous) {
        const currentState = UIState;
        console.log("CURRENT STATE: ", currentState);
        console.log("Current state from useState: ", UIState);

        // Only proceed if still in initial state
        if (currentState === "phoneInput") {
          console.log("LOGIN FLOW LOOP CALLED FROM USE EFFECT");
          loginFlowLoop();
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, [UIState]);

  // PHONE NUMBER INPUT AND VALIDATION
  useEffect(() => {
    if (UIState === "phoneVerification" && phoneOtpInputs.current[0]) {
      phoneOtpInputs.current[0].focus();
    }
    if (UIState === "emailVerification" && emailOtpInputs.current[0]) {
      emailOtpInputs.current[0].focus();
    }
  }, [UIState]);

  useEffect(() => {
    if (
      (UIState === "phoneVerification" || UIState === "emailVerification") &&
      countdownSeconds > 0
    ) {
      const interval = setInterval(() => {
        setCountdownSeconds((seconds) => seconds - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [countdownSeconds, UIState]);

  useEffect(() => {
    try {
      const verifier = new RecaptchaVerifier(auth, "recaptcha-container", {
        size: "invisible",
      });

      window.recaptchaVerifier = verifier;
    } catch (error) {
      console.error("Error initializing reCAPTCHA:", error);
    }

    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = null;
      }
    };
  }, []);

  const handlePhoneNumberInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let input = e.target.value.replace(/\D/g, "");

    if (input.startsWith("61")) {
      // Convert +6104... to +614...
      if (input.startsWith("6104")) {
        input = "614" + input.slice(4);
      }
    } else if (input.startsWith("04")) {
      // Convert +04... to +614...
      input = "614" + input.slice(2);
    }

    const formatted = (() => {
      if (input.length <= 2) return input;
      if (input.length <= 5) return `${input.slice(0, 2)} ${input.slice(2)}`;
      if (input.length <= 8)
        return `${input.slice(0, 2)} ${input.slice(2, 5)} ${input.slice(5, 8)}`;
      return `${input.slice(0, 2)} ${input.slice(2, 5)} ${input.slice(5, 8)} ${input.slice(8)}`;
    })();

    setPhoneNumber(formatted);

    if (
      formatted.length > e.target.value.length &&
      phoneNumberInputRef.current
    ) {
      setTimeout(() => {
        const input = phoneNumberInputRef.current;
        if (input) {
          input.setSelectionRange(formatted.length, formatted.length);
        }
      }, 0);
    }
  };

  const handlePhoneOtpOnClick = (
    _e: React.MouseEvent<HTMLInputElement>,
    index: number
  ) => {
    if (phoneOtp[index]) {
      const newOtp = [...phoneOtp];
      newOtp[index] = "";
      setPhoneOtp(newOtp);
    }
  };

  const handlePhoneOtpKeyInput = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const key = e.key;

    if (key === "Backspace") {
      e.preventDefault();
      const newOtp = [...phoneOtp];
      newOtp[index] = "";
      setPhoneOtp(newOtp);
      if (index > 0) {
        phoneOtpInputs.current[index - 1]?.focus();
      }
    } else if (/^\d$/.test(key)) {
      e.preventDefault();
      const newOtp = [...phoneOtp];
      newOtp[index] = key;
      setPhoneOtp(newOtp);
      if (index < 5) {
        phoneOtpInputs.current[index + 1]?.focus();
      } else {
        // move cursor to end of input in last input
        setTimeout(() => {
          phoneOtpInputs.current[index]?.setSelectionRange(1, 1);
        }, 10);
      }
    }
  };

  const handleSendPhoneOtpCode = async () => {
    setIsLoading(true);
    try {
      if (!window.recaptchaVerifier) {
        console.error("reCAPTCHA not initialized");
        toast.error("Please try again");
        return;
      }

      let formattedPhone = phoneNumber?.startsWith("+")
        ? phoneNumber
        : `+${phoneNumber}`;
      formattedPhone = formattedPhone.replace(/\s/g, "");

      const confirmationResult = await signInWithPhoneNumber(
        auth,
        formattedPhone,
        window.recaptchaVerifier
      );

      window.confirmationResult = confirmationResult;
      setUIState("phoneVerification");
      toast.success(`Verification code sent to +${phoneNumber}!`);
    } catch (error: any) {
      console.error("Phone auth error:", error);
      // Error message needs to have firebase word removed (use regex to account for capitals), and if it contains invalid-phone-number, it should be replaced with "Invalid phone number"
      let modifiedError = error.message.replace(/firebase/gi, "");
      if (modifiedError.includes("invalid-phone-number")) {
        modifiedError = "Invalid phone number";
      }
      toast.error(modifiedError || "Failed to send code");
      // Reset reCAPTCHA on error
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.render().then(function (widgetId: any) {
          grecaptcha.reset(widgetId);
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const [registerMutation] = useRegisterMutation({
    onCompleted: () => {
      console.log("Use register mutation completed successfully");
    },
    onError: (error) => {
      console.error("Error registering user:", error.message);
      toast.error("Error registering user" + error.message);
    },
  });

  const handleVerifyPhoneOtp = async () => {
    setIsLoading(true);
    try {
      await window.confirmationResult.confirm(phoneOtp.join(""));

      console.log("LOGIN FLOW LOOP CALLED FROM VERIFY PHONE OTP");
      loginFlowLoop();
    } catch (error: any) {
      console.error("Error verifying code:", error);
      toast.error("Invalid verification code");
    } finally {
      setIsLoading(false);
    }
  };
  // END PHONE NUMBER INPUT AND VALIDATION

  // PHONE NUMBER INPUT AND VALIDATION
  useEffect(() => {
    if (UIState === "phoneVerification" && phoneOtpInputs.current[0]) {
      phoneOtpInputs.current[0].focus();
    }
    if (UIState === "emailVerification" && emailOtpInputs.current[0]) {
      emailOtpInputs.current[0].focus();
    }
  }, [UIState]);

  useEffect(() => {
    try {
      const verifier = new RecaptchaVerifier(auth, "recaptcha-container", {
        size: "invisible",
      });

      window.recaptchaVerifier = verifier;
    } catch (error) {
      console.error("Error initializing reCAPTCHA:", error);
    }

    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = null;
      }
    };
  }, []);

  const handleEmailOtpOnClick = (
    _e: React.MouseEvent<HTMLInputElement>,
    index: number
  ) => {
    if (emailOtp[index]) {
      const newOtp = [...emailOtp];
      newOtp[index] = "";
      setEmailOtp(newOtp);
    }
  };

  const handleEmailOtpKeyInput = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const key = e.key;

    if (key === "Backspace") {
      e.preventDefault();
      const newOtp = [...emailOtp];
      console.log("newOtp: ", newOtp);
      newOtp[index] = "";
      console.log("modified newOtp: ", newOtp);
      setEmailOtp(newOtp);
      if (index > 0) {
        emailOtpInputs.current[index - 1]?.focus();
      }
    } else if (/^\d$/.test(key)) {
      e.preventDefault();
      const newOtp = [...emailOtp];
      newOtp[index] = key;
      setEmailOtp(newOtp);
      if (index < 5) {
        emailOtpInputs.current[index + 1]?.focus();
      } else {
        // move cursor to end of input in last input
        setTimeout(() => {
          emailOtpInputs.current[index]?.setSelectionRange(1, 1);
        }, 10);
      }
    } else {
      e.preventDefault();
    }
  };

  const handlePastePhoneOtp = (
    e: React.ClipboardEvent<HTMLInputElement> | string
  ) => {
    let paste = "";
    if (typeof e === "string") {
      paste = e;
    } else {
      e.preventDefault();
      paste = e.clipboardData.getData("text");
    }

    const pasteArray = paste.split("");
    const newOtp = [...phoneOtp];
    if (
      pasteArray.length > 6 ||
      pasteArray.length < 6 ||
      !/^\d+$/.test(paste)
    ) {
      return;
    }
    pasteArray.forEach((char, index) => {
      newOtp[index] = char;
    });
    setPhoneOtp(newOtp);
  };

  const handlePasteEmailOtp = (
    e: React.ClipboardEvent<HTMLInputElement> | string
  ) => {
    let paste = "";
    if (typeof e === "string") {
      paste = e;
    } else {
      e.preventDefault();
      paste = e.clipboardData.getData("text");
    }
    const pasteArray = paste.split("");
    const newOtp = [...emailOtp];
    if (
      pasteArray.length > 6 ||
      pasteArray.length < 6 ||
      !/^\d+$/.test(paste)
    ) {
      return;
    }
    pasteArray.forEach((char, index) => {
      newOtp[index] = char;
    });
    setEmailOtp(newOtp);
  };

  const handleSendEmailOtpCode = async () => {
    setIsLoading(true);
    try {
      await requestEmailOtp({
        variables: { email },
      });
      setCountdownSeconds(30);
      setUIState("emailVerification");
      toast.success(`Verification code sent to ${email}`);
    } catch (error: any) {
      console.error("Email OTP error:", error);
      toast.error(error.message || "Failed to send code");
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyEmailOtp = async () => {
    setIsLoading(true);
    try {
      await verifyEmailOtp({
        variables: {
          email,
          otp: emailOtp.join(""),
        },
      });
      console.log("LOGIN FLOW LOOP CALLED FROM VERIFY EMAIL OTP");
      loginFlowLoop();
    } catch (error: any) {
      console.error("Error verifying email:", error);
      if (error.message.includes("expired")) {
        toast.error(
          "Verification code has expired. Please go back and request a new one"
        );
      } else {
        toast.error("Invalid verification code");
      }
    } finally {
      setIsLoading(false);
    }
  };
  // END EMAIL INPUT AND VALIDATION

  // NAME INPUT AND VALIDATION
  const handleSubmitName = async () => {
    setIsLoading(true);

    try {
      // TODO: Make it update in the db
      if (!firstName || !lastName) {
        setIsLoading(false);
        return;
      }

      await mutateMeMutation({
        variables: {
          data: {
            firstName,
            lastName,
          },
        },
        context: {
          headers: {
            "apollo-require-preflight": true,
          },
        },
      });

      console.log("LOGIN FLOW LOOP CALLED FROM SUBMIT NAME");
      loginFlowLoop();
    } catch (error) {
      console.error("Error updating name:", error);
      toast.error("Error updating name");
      setIsLoading(false);
    }
  };

  const [tryFetchUser] = useMeLazyQuery({
    fetchPolicy: "network-only",
  });

  const checkUserIsLoggedIn = async () => {
    if (auth?.currentUser && !auth?.currentUser?.isAnonymous) {
      return true;
    } else {
      return false;
    }
  };

  const checkUserExistsInDB = async (freshMeData: MeQuery | undefined) => {
    if (freshMeData?.me) {
      return true;
    } else {
      return false;
    }
  };

  const checkEmailIsVerified = async (freshMeData: MeQuery | undefined) => {
    if (freshMeData?.me && !freshMeData?.me?.email?.endsWith("phone.user")) {
      return true;
    } else {
      return false;
    }
  };

  const checkNameIsSet = async (freshMeData: MeQuery | undefined) => {
    if (
      freshMeData?.me &&
      freshMeData?.me?.firstName &&
      freshMeData?.me?.lastName
    ) {
      return true;
    } else {
      return false;
    }
  };

  const loginFlowLoop = async () => {
    console.log("LOGIN FLOW LOOP STARTING");
    // check if user is logged in
    const userIsLoggedIn = await checkUserIsLoggedIn();
    console.log("userIsLoggedIn: ", userIsLoggedIn);
    if (!userIsLoggedIn) {
      setUIState("phoneInput");
      return;
    }

    const { data: freshMeData } = await tryFetchUser();

    const doesUserExistInDB = await checkUserExistsInDB(freshMeData);
    console.log("doesUserExistInDB: ", doesUserExistInDB);
    if (!doesUserExistInDB && auth.currentUser) {
      try {
        await registerMutation({
          variables: {
            firstName: "",
            lastName: "",
            email: `${auth.currentUser.uid}@phone.user`,
            contactNumber: auth.currentUser.phoneNumber ?? "",
            userType: "user",
            firebaseUid: auth.currentUser.uid,
          },
        });

        console.log("USER REGISTERED SUCCESSFULLY: ", auth.currentUser.uid);
      } catch (error) {
        console.error("Error registering user:", error);
        toast.error("Error registering user");
        return;
      }
    }

    const emailIsVerified = await checkEmailIsVerified(freshMeData);
    if (!emailIsVerified) {
      setUIState("emailInput");
      return;
    }

    const nameIsSet = await checkNameIsSet(freshMeData);
    if (!nameIsSet) {
      setUIState("nameInput");
      return;
    }

    const redirectPathToPayment = localStorage.getItem("redirectPathToPayment");

    if (redirectPathToPayment) {
      router.push(redirectPathToPayment);
      setTimeout(() => {
        localStorage.removeItem("redirectPathToPayment");
        localStorage.removeItem("redirectPathToCart");
      }, 1000);
    } else {
      router.push("/landing");
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-white justify-center items-center w-full max-w-full">
      <ToastContainer position="top-left" autoClose={5000} />
      <div className="flex flex-col min-h-screen  justify-center w-full max-w-80 items-center p-4">
        <img
          src="/new_logos/newYWaitLogoText.png"
          className="h-20 object-contain mobile:h-16 mb-4"
          alt="YWait Logo"
        />
        <div className="flex flex-col justify-center items-center max-w-full w-full gap-2 text-center">
          {UIState === "phoneInput" && (
            <>
              <p className="pt-10 pb-6 text-sm">
                Please enter your phone number to continue. You will receive a
                verification code and updates about your orders.
              </p>
              <div className="relative w-full max-w-64">
                <span className="absolute left-3 top-2 text-gray-500">+</span>
                <input
                  ref={phoneNumberInputRef}
                  type="tel"
                  autoComplete="tel"
                  name="phone"
                  value={phoneNumber}
                  onChange={handlePhoneNumberInputChange}
                  className="w-full px-6 py-2 border rounded-md focus:ring-2 focus:ring-5b5-softer-blue focus:border-5b5-softer-blue outline-none"
                  placeholder="61  ###  ###  ###"
                />
              </div>
              <button
                onClick={handleSendPhoneOtpCode}
                id="submit-phone-number-button"
                disabled={phoneNumber.length < 12}
                className={`w-full flex items-center justify-center font-medium bg-5b5-softer-blue h-11 rounded-md max-w-64 p-2.5 text-white disabled:opacity-50 ${isLoading ? "opacity-50" : ""} `}
              >
                {isLoading ? (
                  <FaSpinner className="animate-spin text-xl" />
                ) : (
                  "Continue"
                )}
              </button>
              <PrivacyPolicyTCDisclaimer />
              <button
                onClick={() => {
                  // get redirect path to cart from localStorage
                  const redirectPath =
                    localStorage.getItem("redirectPathToCart");
                  if (redirectPath) {
                    router.push(redirectPath);
                    setTimeout(() => {
                      localStorage.removeItem("redirectPathToCart");
                      localStorage.removeItem("redirectPathToPayment");
                    }, 1000);
                  } else {
                    router.push("/landing");
                  }
                }}
                className="flex items-center justify-center text-5b5-softer-blue text-xs px-4 mt-4"
              >
                <IoArrowBack />
                <span className=" ml-2">Back</span>
              </button>
            </>
          )}
          {UIState === "phoneVerification" && (
            <>
              <p className="pt-10 pb-6">
                Thank you! We sent a verification code to{" "}
                <strong>+{phoneNumber}</strong>. Please enter the code below. It
                may take a few minutes to arrive.
              </p>
              <div className="flex justify-between w-72">
                {phoneOtp.map((digit, index) => (
                  <input
                    key={`${index}-${digit}`}
                    type="tel"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    maxLength={1}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.length === 6 && /^\d{6}$/.test(value)) {
                        handlePastePhoneOtp(value);
                      }
                    }}
                    defaultValue={phoneOtp[index]}
                    onKeyDown={(e) => handlePhoneOtpKeyInput(e, index)}
                    onPaste={handlePastePhoneOtp}
                    onClick={(e) => handlePhoneOtpOnClick(e, index)}
                    ref={(el) => (phoneOtpInputs.current[index] = el)}
                    className="min-w-[40px] w-[40px] min-h-[40px] h-[40px] text-center border rounded-md focus:ring-2 focus:ring-5b5-softer-blue focus:border-5b5-softer-blue outline-none"
                  />
                ))}
              </div>
              <button
                onClick={() => {
                  handleVerifyPhoneOtp();
                }}
                disabled={phoneOtp.some((otp) => !otp)}
                className={`w-full flex items-center justify-center font-medium bg-5b5-softer-blue h-11 rounded-md max-w-72 p-2.5 text-white disabled:opacity-50 ${isLoading ? "opacity-50" : ""} `}
              >
                {isLoading ? (
                  <FaSpinner className="animate-spin text-xl" />
                ) : (
                  "Continue"
                )}
              </button>
              <PrivacyPolicyTCDisclaimer />
              <button
                disabled={countdownSeconds > 0}
                onClick={() => {
                  setPhoneOtp(Array(6).fill(""));
                  setUIState("phoneInput");
                  // reload page
                  window.location.reload();
                }}
                className="flex items-center justify-center text-5b5-softer-blue text-xs px-4 mt-4"
              >
                <IoArrowBack />
                <span className=" ml-2">Back</span>
                {countdownSeconds > 0 && (
                  <span className="ml-1">({countdownSeconds}s)</span>
                )}
              </button>
            </>
          )}
          {UIState === "emailInput" && (
            <>
              <div className="border border-gray-500 rounded-md mt-7 py-2 px-4 w-full max-w-64 flex items-center justify-between">
                <p className="">
                  +
                  {phoneNumber.length > 3
                    ? phoneNumber
                    : auth.currentUser?.phoneNumber
                        ?.replace("+", "")
                        .replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, "$1 $2 $3 $4")}
                </p>
                <AiOutlineCheckCircle className="text-green-500 text-xl" />
              </div>
              <p className="pt-6 pb-6">
                We will email you receipts, as well as updates about your order.
                Please enter your email
              </p>
              <div className="relative w-full max-w-64">
                <input
                  type="email"
                  autoComplete="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full py-2 border rounded-md focus:ring-2 focus:ring-5b5-softer-blue focus:border-5b5-softer-blue outline-none"
                  placeholder="example@email.com"
                />
              </div>
              <button
                onClick={handleSendEmailOtpCode}
                id="submit-phone-number-button"
                disabled={
                  email.length < 5 ||
                  !email.includes("@") ||
                  !email.includes(".") ||
                  email.includes(" ") ||
                  email.includes(",") ||
                  email.includes(";")
                }
                className={`w-full flex items-center justify-center font-medium bg-5b5-softer-blue h-11 rounded-md max-w-64 p-2.5 text-white disabled:opacity-50 ${isLoading ? "opacity-50" : ""} `}
              >
                {isLoading ? (
                  <FaSpinner className="animate-spin text-xl" />
                ) : (
                  "Continue"
                )}
              </button>
              <PrivacyPolicyTCDisclaimer />
            </>
          )}
          {UIState === "emailVerification" && (
            <>
              <p className="pt-10 pb-6">
                Thank you! We sent a verification code to{" "}
                <strong>{email}</strong>. Please enter the code below. It may
                take a few minutes to arrive.
              </p>
              <div className="flex justify-between w-72">
                {emailOtp.map((digit, index) => (
                  <input
                    key={`${index}-${digit}`}
                    type="tel"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    maxLength={1}
                    defaultValue={emailOtp[index]}
                    onKeyDown={(e) => handleEmailOtpKeyInput(e, index)}
                    onPaste={handlePasteEmailOtp}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.length === 6 && /^\d{6}$/.test(value)) {
                        handlePasteEmailOtp(value);
                      }
                    }}
                    onClick={(e) => handleEmailOtpOnClick(e, index)}
                    ref={(el) => (emailOtpInputs.current[index] = el)}
                    className="min-w-[40px] w-[40px] min-h-[40px] h-[40px] text-center border rounded-md focus:ring-2 focus:ring-5b5-softer-blue focus:border-5b5-softer-blue outline-none"
                  />
                ))}
              </div>
              <button
                onClick={() => {
                  handleVerifyEmailOtp();
                }}
                disabled={emailOtp.some((otp) => !otp)}
                className={`w-full flex items-center justify-center font-medium bg-5b5-softer-blue h-11 rounded-md max-w-72 p-2.5 text-white disabled:opacity-50 ${isLoading ? "opacity-50" : ""} `}
              >
                {isLoading ? (
                  <FaSpinner className="animate-spin text-xl" />
                ) : (
                  "Continue"
                )}
              </button>
              <PrivacyPolicyTCDisclaimer />
              <button
                disabled={countdownSeconds > 0}
                onClick={() => {
                  setEmailOtp(Array(6).fill(""));
                  setUIState("emailInput");
                }}
                className="flex items-center justify-center text-5b5-softer-blue text-xs px-4 mt-4"
              >
                <IoArrowBack />
                <span className=" ml-2">Back</span>
                {countdownSeconds > 0 && (
                  <span className="ml-1">({countdownSeconds}s)</span>
                )}
              </button>
            </>
          )}
          {UIState === "nameInput" && (
            <>
              <div className="flex flex-col gap-0 justify-center items-center w-full max-w-64 ">
                <div className="border border-gray-500 rounded-md rounded-b-none mt-7 py-2 px-4 w-full max-w-64 flex items-center justify-between">
                  <p className="">
                    +
                    {phoneNumber.length > 3
                      ? phoneNumber
                      : auth.currentUser?.phoneNumber
                          ?.replace("+", "")
                          .replace(
                            /(\d{2})(\d{3})(\d{3})(\d{3})/,
                            "$1 $2 $3 $4"
                          )}
                  </p>
                  <AiOutlineCheckCircle className="text-green-500 text-xl" />
                </div>

                <div className="border border-gray-500 rounded-md rounded-t-none border-t-0 py-2 px-4 w-full max-w-64 flex items-center justify-between">
                  <p className="truncate">
                    {email.length > 0 ? email : meData?.me?.email}
                  </p>
                  <AiOutlineCheckCircle className="flex-shrink-0 text-green-500 text-xl" />
                </div>
              </div>
              <p className="pt-6 pb-6">
                So we can make sure your order goes to the right person, please
                enter your name
              </p>
              <div className="relative w-full max-w-64">
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="w-full py-2 border rounded-md focus:ring-2 focus:ring-5b5-softer-blue focus:border-5b5-softer-blue outline-none"
                  placeholder="First Name"
                  autoComplete="given-name"
                  name="given-name"
                />
              </div>
              <div className="relative w-full max-w-64">
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="w-full py-2 border rounded-md focus:ring-2 focus:ring-5b5-softer-blue focus:border-5b5-softer-blue outline-none"
                  placeholder="Last Name"
                  autoComplete="family-name"
                  name="family-name"
                />
              </div>
              <button
                onClick={handleSubmitName}
                id="submit-phone-number-button"
                disabled={
                  firstName.length < 1 || lastName.length < 1 || isLoading
                }
                className={`w-full flex items-center justify-center font-medium bg-5b5-softer-blue h-11 rounded-md max-w-64 p-2.5 text-white disabled:opacity-50 ${isLoading ? "opacity-50" : ""} `}
              >
                {isLoading ? (
                  <FaSpinner className="animate-spin text-xl" />
                ) : (
                  "Continue"
                )}
              </button>
              <PrivacyPolicyTCDisclaimer />
            </>
          )}
          <div id="recaptcha-container" />
        </div>
      </div>
    </div>
  );
};

const PrivacyPolicyTCDisclaimer = () => {
  return (
    <span className="text-xs text-gray-500 mt-4">
      By continuing, you agree to our{" "}
      <Link href="/legal/termsAndConditions" className="text-blue-500">
        Terms & Conditions
      </Link>{" "}
      and{" "}
      <Link href="/legal/privacyPolicy" className="text-blue-500">
        Privacy Policy
      </Link>
    </span>
  );
};

export default Login2;
